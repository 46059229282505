import { TreeItem } from "@mui/x-tree-view/TreeItem"
import React, { useEffect } from "react"
import { useState } from "react"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView"
import { set } from "react-hook-form"

export default function TreeViewComponent({
  workplaces = [],
  props,
  setProps,
}) {
  // Convert workplaces to an array if it is an object
  const workplacesArray = Array.isArray(workplaces) ? workplaces : [workplaces]

  console.log(props.employerName)
  console.log(props.employerId)
  console.log(props.notAWorkplace)
  console.log(props.lastSelectedItem)
  console.log(workplacesArray)

  useEffect(() => {
    setProps.setLastSelectedItem(null)
    setProps.setLastSelectedItemId(null)
    setProps.setEmployerName(null)
    setProps.setEmployerId(null)
    setProps.setNotAWorkplace(false)
  }, [workplaces])

  const renderTree = nodes => {
    return nodes && nodes.length > 0
      ? nodes.map(node => (
          <TreeItem
            key={node.id}
            itemId={node.id}
            label={
              <span
                style={{
                  color: node.isWorkplace === false ? "red" : "inherit",
                }}
              >
                {node.name}
              </span>
            }
            classes={{
              checkbox: "MuiTreeItem-checkbox-hidden",
            }}
          >
            {node.accountChildren && node.accountChildren.length > 0
              ? renderTree(node.accountChildren)
              : null}
          </TreeItem>
        ))
      : null
  }

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean
  ) => {
    if (isSelected === true) {
      // Find the selected item in the tree
      const selectedItem = findItemById(workplacesArray, itemId)
      if (selectedItem) {
        setProps.setEmployerName(selectedItem?.parentAccount?.name)
        setProps.setEmployerId(selectedItem?.parentAccount?.id)
        setProps.setLastSelectedItem(selectedItem?.name)
        setProps.setLastSelectedItemId(itemId)
        if (selectedItem.isWorkplace === false) {
          setProps.setNotAWorkplace(true)
        }
      }
    } else {
      setProps.setLastSelectedItem(null)
      setProps.setLastSelectedItemId(null)
      setProps.setEmployerName(null)
      setProps.setEmployerId(null)
      setProps.setNotAWorkplace(false)
    }
  }

  // Helper function to find an item by its itemId in the tree
  const findItemById = (items, itemId) => {
    for (const item of items) {
      if (item.id === itemId) {
        return item
      }
      if (item.accountChildren) {
        const foundItem = findItemById(item.accountChildren, itemId)
        if (foundItem) {
          return foundItem
        }
      }
    }
    return null
  }

  // Rest of your component remains unchanged
  return (
    <Stack spacing={2}>
      {props.employerName == null ? (
        ""
      ) : (
        <Typography>
          {" "}
          <span className=" font-semibold">Arbeidsgiver:</span>{" "}
          {props.employerName}
        </Typography>
      )}

      {props.lastSelectedItem == null ? (
        ""
      ) : (
        <Typography>
          {" "}
          <span className=" font-semibold">Arbeidssted:</span>{" "}
          {props.lastSelectedItem}
        </Typography>
      )}

      {props.notAWorkplace && (
        <Typography className="block font-semibold sm:col-span-2 sm:mt-px sm:pt-2 sm:col-start-3">
          {props.lastSelectedItem} er ikke et gyldig arbeidssted
        </Typography>
      )}

      <div>
        <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
          Rød skrift indikerer at valgt arbeidssted ikke er gyldig.
        </p>
      </div>
      <Box sx={{ minHeight: 300, minWidth: 200, flexGrow: 1 }}>
        <SimpleTreeView
          checkboxSelection
          onItemSelectionToggle={handleItemSelectionToggle}
        >
          {renderTree(workplacesArray)}
        </SimpleTreeView>
      </Box>
    </Stack>
  )
}
