import React, { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { Account, Employment } from "../../../../Utils/Entities"
import { navigate } from "gatsby"
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker"
import nb from "date-fns/locale/nb"
import "react-datepicker/dist/react-datepicker.css"
import {
  fetchApi,
  patchApi,
  patchApiAnonymous,
} from "../../../../Utils/Api.utils"
import { InteractionType } from "@azure/msal-browser"
import {
  useMsal,
  useAccount,
  MsalAuthenticationTemplate,
} from "@azure/msal-react"
import { Loading } from "../../../../Utils/Loading"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import MyPageLayout2 from "../../../common/layouts/myPageLayout2"
import { loginRequest } from "../../../security/authConfig"
import EditWork from "../../profil/EditWork"
import { useMutation, useQuery } from "react-query"
import LoadingData from "../../../common/graphichs/LoadingData"
import { toast } from "react-toastify"
registerLocale("nb", nb)

export type formValues = {
  workId: string
  endDate: Date
}

const EndAllEmployementsContent = ({ retired }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({})

  const [employment, setEmployment] = useState<Employment>()

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)
  const [endAllEmployments, setEndAllEmployments] = useState<string>("")
  const [employments, setEmployments] = useState<Employment[]>([])
  const [employmentId, setEmploymentId] = useState<string>("")
  const [timer, setTimer] = useState<number>(2500)

  console.log(retired)

  const employmentQuery = useQuery(
    "employment",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Employments",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        // setEmployments(data)
        data?.find(work => {
          const endDate = work?.endDate ? new Date(work.endDate) : undefined
          if (!endDate || endDate > new Date()) {
            // setEmployments(data)
            setEmployments(newValues => {
              if (!newValues.includes(work?.id)) {
                return [...newValues, work?.id]
              }
              return newValues
            })
          }
        })
      },
      onError: _error => {},
    }
  )

  const mutationUpdateEmployment = useMutation(
    data => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Employments/${data?.workId}`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        console.log(data)
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  const saveChanges = async data => {
    setShowLoadingData(true)
    const mutationPromises = employments.map(employment => {
      const objects: formValues = {
        workId: employment.toString(),
        endDate: data.endDate, // Assuming each employment object has an endDate property
      }
      console.log(objects)
      return mutationUpdateEmployment.mutateAsync(objects)
    })
    try {
      await Promise.all(mutationPromises)
      await patchApi(
        `${process.env.GATSBY_API_URL}/Contacts/me`,
        retired ? { retired: true } : { professionallyActiveStatus: 292460001 },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    } catch (error) {
      toast.error("Det har skjedd en feil, prøv igjen senere", {
        position: "top-center",
        autoClose: 6000,
      })
    } finally {
      window.setTimeout(() => {
        navigate("/app/profil/arbeidsforhold")
      }, timer)
      console.log("Done")
    }
  }

  // console.log(employments)

  // console.log(endallemployments)

  return showLoadingData ? (
    <LoadingData />
  ) : (
    <>
      {retired ? (
        <MyPageLayout2 props="Rediger Arbeidsforhold">
          <div className="space-y-8 md:space-y-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
              <label
                htmlFor="endAllEmployments"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Vil du fortsette dine arbeidsforhold?:
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  defaultValue={""}
                  onChange={option => setEndAllEmployments(option.target.value)}
                  className=" disabled:bg-gray-200 disabled:text-opacity-50 block max-w-sm w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="" disabled>
                    -- Velg et svar --
                  </option>
                  <option value="Yes">Ja</option>
                  <option value="No">Nei</option>
                </select>
              </div>
            </div>
            {endAllEmployments === "No" && (
              <div>
                <form
                  method="PATCH"
                  onSubmit={handleSubmit(saveChanges)}
                  className="space-y-8 "
                >
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="endDate"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Oppgi sluttdato:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Controller
                        control={control}
                        name="endDate"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <ReactDatePicker
                            locale="nb"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            dateFormat="dd/MM/yyyy"
                            className="block max-w-sm w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="pt-5 flex justify-end">
                    <button
                      type="button"
                      onClick={() => history.back()}
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                    >
                      Tilbake
                    </button>
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                    >
                      Lagre endringer
                    </button>
                  </div>
                </form>
              </div>
            )}
            {endAllEmployments === "Yes" && <div></div>}
          </div>
        </MyPageLayout2>
      ) : (
        <MyPageLayout2 props="Avslutt alle dine arbeidsforhold">
          <div className="space-y-8 md:space-y-4">
            <div>
              <form
                method="PATCH"
                onSubmit={handleSubmit(saveChanges)}
                className="space-y-8 "
              >
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="endDate"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Oppgi sluttdato:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          locale="nb"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          dateFormat="dd/MM/yyyy"
                          className="block max-w-sm w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="pt-5 flex justify-end">
                  <button
                    type="button"
                    onClick={() => navigate("/app/profil/arbeidsforhold")}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  >
                    Tilbake
                  </button>
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  >
                    Lagre endringer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </MyPageLayout2>
      )}
    </>
  )
}
// export default EditWork

const EndAllEmployements = location => {
  const authRequest = {
    ...loginRequest,
  }
  const retired = location?.location?.state?.retired
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EndAllEmployementsContent retired={retired} />
    </MsalAuthenticationTemplate>
  )
}

export default EndAllEmployements
